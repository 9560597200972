import React, { useState } from 'react';
import { Link, graphql, Script } from 'gatsby';
import {
  LayoutLp,
  SEO,
  AssetImage,
  LWrap,
  HeaderLpCustom,
  CBtnList,
  CBtn,
  CYoutube,
} from '../../../components/_index';
import { useMobile } from '../../../utils/use-series';
import '../../../assets/_sass/page/sp/gardenresort.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const isSp = useMobile();
  const [openFlag, setOpenFlag] = useState(false);
  const [openFlag02, setOpenFlag02] = useState(false);
  const [openFlag03, setOpenFlag03] = useState(false);
  const [openFlag04, setOpenFlag04] = useState(false);
  const [openFlag05, setOpenFlag05] = useState(false);
  const openClick = (e: any) => {
    setOpenFlag(true);
  };
  const closeClick = (e: any) => {
    setOpenFlag(false);
  };
  const openClick02 = (e: any) => {
    setOpenFlag02(true);
  };
  const closeClick02 = (e: any) => {
    setOpenFlag02(false);
  };
  const openClick03 = (e: any) => {
    setOpenFlag03(true);
  };
  const closeClick03 = (e: any) => {
    setOpenFlag03(false);
  };
  const openClick04 = (e: any) => {
    setOpenFlag04(true);
  };
  const closeClick04 = (e: any) => {
    setOpenFlag04(false);
  };
  const openClick05 = (e: any) => {
    setOpenFlag05(true);
  };
  const closeClick05 = (e: any) => {
    setOpenFlag05(false);
  };

  // const [video, setVideo] = document.querySelector('#video');

  return (
    <>
      {/* <Script
        dangerouslySetInnerHTML={{
          __html: `
            (function(){
              const video = document.querySelector('#video');
              const video_btn = document.querySelector('#video-btn');
              let is_playing = false;
            
              video_btn.addEventListener('click', () => {
                if (!is_playing) {
                  video.play();
                  is_playing = true;
                } else {
                  video.pause();
                  is_playing = false;
                }
              });
              console.log("aaa");
            })(document)
          `,
        }}
      /> */}

      <LayoutLp>
        <SEO
          title={frontmatter?.title}
          description={frontmatter?.description}
          ogimage={`${process.env.BASE_URL}assets/images/sp/gardenresort/ogimage.png`}
        />
        <header className="l_headerLp l_headergreenplan">
          <div className="container">
            <div className="inner">
              <p className="logo">
                <Link to="/">
                  <AssetImage
                    src="/assets/images/sp/gardenresort/logo.svg"
                    alt="THE ROYAL PARK SENDAI"
                    loading="lazy"
                  />
                </Link>
              </p>
            </div>
          </div>
        </header>
        <main>
          <div className="p_greenplan">
            <section className="lp_kv">
              {!isSp ? (
                <figure className="imgWrapper">
                  <AssetImage
                    src="/assets/images/sp/gardenresort/kv.png"
                    alt=""
                    loading="lazy"
                  />
                </figure>
              ) : (
                <figure className="imgWrapper">
                  <AssetImage
                    src="/assets/images/sp/gardenresort/kv__sp.png"
                    alt=""
                    loading="lazy"
                  />
                </figure>
              )}
            </section>

            <LWrap exClass="u_mbMiddleLarge">
              <div className="lp_hotelUnit">
                <div className="lp_hotelMedia">
                  <figure className="imgWrapper">
                    {!isSp ? (
                      <AssetImage
                        src="/assets/images/sp/gardenresort/img_hotel.png"
                        alt=""
                        loading="lazy"
                      />
                    ) : (
                      <AssetImage
                        src="/assets/images/sp/gardenresort/img_hotel.png"
                        alt=""
                        loading="lazy"
                      />
                    )}
                  </figure>
                </div>
                <div className="lp_hotelBody">
                  <div className="lp_hotelBodyTitle">
                    幻想的なガーデンを眺め、
                    <br />
                    冬の澄んだ空気を感じながら
                    <br />
                    過ごす特別な時間
                  </div>
                </div>
              </div>
              <div className="lp_hotelautumnUnit lp_hotelautumnUnit2">
                <div className="lp_hotelautumnMedia">
                  <figure className="imgWrapper03">
                    {!isSp ? (
                      <AssetImage
                        src="/assets/images/sp/gardenresort/img_hotel02.png"
                        alt=""
                        loading="lazy"
                      />
                    ) : (
                      <AssetImage
                        src="/assets/images/sp/gardenresort/img_hotel02.png"
                        alt=""
                        loading="lazy"
                      />
                    )}
                  </figure>
                  <figure className="imgWrapper04">
                    {!isSp ? (
                      <AssetImage
                        src="/assets/images/sp/gardenresort/img_hotel03.png"
                        alt=""
                        loading="lazy"
                      />
                    ) : (
                      <AssetImage
                        src="/assets/images/sp/gardenresort/img_hotel03.png"
                        alt=""
                        loading="lazy"
                      />
                    )}
                  </figure>
                </div>
                <div className="lp_hotelautumnBody">
                  <div className="lp_hotelautumnBodyText">
                    冬ならではの煌めくガーデンを眺めながらフリーフローとともに嗜むディナータイム。東北‧宮城の⾷材を使⽤したこの時季ならではの⼼も⾝体も温まるメニューを味わう。チェックイン後は焚火を囲んでリゾート体験。⾮⽇常の空間でここにしかない冬の仙台リゾートステイ。
                    {/* <br /> */}
                    {/* 五感のすべてを開放するここにしかない秋の仙台リゾートステイをお得にご堪能ください。 */}
                  </div>
                </div>
              </div>
              <div className="lp_hotelautumnUnit">
                <div className="lp_hotelautumnMedia">
                  <figure className="imgWrapper02">
                    {!isSp ? (
                      <AssetImage
                        src="/assets/images/sp/gardenresort/img_hotel04.png"
                        alt=""
                        loading="lazy"
                      />
                    ) : (
                      <AssetImage
                        src="/assets/images/sp/gardenresort/img_hotel04.png"
                        alt=""
                        loading="lazy"
                      />
                    )}
                  </figure>
                </div>
              </div>
              <h3 className="lp_title lp_title_mt60">
                <span>
                  <AssetImage
                    src="/assets/images/sp/gardenresort/img_point_en.png"
                    alt=""
                    loading="lazy"
                  />
                </span>
                おすすめポイント
              </h3>
              <div className="lp_recommendationUnit">
                <div className="lp_recommendationMedia">
                  <div className="imgWrapper">
                    <figure>
                      <AssetImage
                        src="/assets/images/sp/gardenresort/img_point02.png"
                        alt=""
                        loading="lazy"
                      />
                    </figure>
                  </div>
                  <div className="lp_recommendationBody">
                    <p className="lp_recommendationPoint">Point 01</p>
                    <p className="lp_recommendationTitle">
                    アフタヌーンティーなどのスイーツをはじめ、⽩いちご化粧品を使⽤したエステなどさまざまな苺の魅力を楽しめる「ストロベリーフェスティバル」を開催
                    </p>
                  </div>
                </div>
                <div className="lp_recommendationMedia">
                  <div className="imgWrapper">
                    <figure>
                      <AssetImage

                        src="/assets/images/sp/gardenresort/img_point.png"
                        alt=""
                        loading="lazy"
                      />
                    </figure>
                  </div>
                  <div className="lp_recommendationBody">
                    <p className="lp_recommendationPoint">Point 02</p>
                    <p className="lp_recommendationTitle">
                      フリーフロー付プランでお財布を気にすることなく⼼も⾝体も温まる⾷事をたのしむ。さらに2食付き宿泊プランではマシュマロや仙台名物の笹かまを焚火でたのしむ特典付き
                    </p>
                  </div>
                </div>
                <div className="lp_recommendationMedia">
                  <div className="imgWrapper">
                    <figure>
                      <AssetImage
                        src="/assets/images/sp/gardenresort/img_point03.png"
                        alt=""
                        loading="lazy"
                      />
                    </figure>
                  </div>
                  <div className="lp_recommendationBody">
                    <p className="lp_recommendationPoint">Point 03</p>
                    <p className="lp_recommendationTitle">
                      「温活」をテーマにホットストーンを使⽤したエステで⽇頃の疲れを癒す
                    </p>
                  </div>
                </div>
              </div>
              <h3 className="lp_title lp_title_mt60">
                <span>
                  <AssetImage
                    src="/assets/images/sp/gardenresort/img_plan_en.png"
                    alt=""
                    loading="lazy"
                  />
                </span>
                おすすめ宿泊プラン
              </h3>
              <div className="lp_plan02">
                <div className="lp_plan02List">
                  <div className="lp_plan02ListItem">
                    <div className="lp_plan02ListNumber">
                      <div className="lp_plan02ListNumberPlan">Plan 01</div>
                    </div>
                    <div className="lp_plan02ListContents">
                      <div className="lp_plan02ListName">
                        【冬のステイケーション】苺スイーツと冬のグリル&オーブンフレンチディナー付（ドリンクフリーフロー）
                      </div>
                      <div className="lp_plan02ListImg">
                        <a href="http://rsv.ihonex.com/cgi-bin/ihonex3/plan_shokai.cgi?hid=rphs_sendai&plan_groupcd=DN24112101&form=jp" target="_blank">
                          {!isSp ? (
                            <AssetImage
                              src="/assets/images/sp/gardenresort/img_plan.png"
                              alt=""
                              loading="lazy"
                            />
                          ) : (
                            <AssetImage
                              src="/assets/images/sp/gardenresort/img_plan__sp.png"
                              alt=""
                              loading="lazy"
                            />
                          )}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lp_plan02List">
                  <div className="lp_plan02ListItem">
                    <div className="lp_plan02ListNumber">
                      <div className="lp_plan02ListNumberPlan">Plan 02</div>
                    </div>
                    <div className="lp_plan02ListContents">
                      <div className="lp_plan02ListName">
                        【ドリンクフリーフロー】手巻きで楽しむ”北京ダック食べ放題”ディナー付き
                      </div>
                      <div className="lp_plan02ListImg">
                        <a href="https://rsv.ihonex.com/cgi-bin/ihonex3/plan_shokai.cgi?hid=rphs_sendai&plan_groupcd=DN24120601&form=jp" target="_blank">
                          {!isSp ? (
                            <AssetImage
                              src="/assets/images/sp/gardenresort/img_plan02.png"
                              alt=""
                              loading="lazy"
                            />
                          ) : (
                            <AssetImage
                              src="/assets/images/sp/gardenresort/img_plan02__sp.png"
                              alt=""
                              loading="lazy"
                            />
                          )}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lp_plan02List">
                  <div className="lp_plan02ListItem">
                    <div className="lp_plan02ListNumber">
                      <div className="lp_plan02ListNumberPlan">Plan 03</div>
                    </div>
                    <div className="lp_plan02ListContents">
                      <div className="lp_plan02ListName">
                        シーズナルエステ【温活】冬のおすすめオールハンドエステ60分1名様施術込(朝⾷付)
                      </div>
                      <div className="lp_plan02ListImg03">
                        <a href="https://rsv.ihonex.com/cgi-bin/ihonex3/plan_shokai.cgi?hid=rphs_sendai&plan_groupcd=ES24102401&form=jp" target="_blank">

                          {!isSp ? (
                            <AssetImage
                              src="/assets/images/sp/gardenresort/img_plan03.png"
                              alt=""
                              loading="lazy"
                            />
                          ) : (
                            <AssetImage
                              src="/assets/images/sp/gardenresort/img_plan03__sp.png"
                              alt=""
                              loading="lazy"
                            />
                          )}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lp_plan02List">
                </div>
              </div>

              <h3 className="lp_title">
                <span>
                  <AssetImage
                    src="/assets/images/sp/gardenresort/img_activity_en.png"
                    alt=""
                    loading="lazy"
                  />
                </span>
                アクティビティ
              </h3>
              <div className="lp_limitedUnit">
                <div className="lp_limitedMedia">
                  <div className="imgWrapper">
                    {!isSp ? (
                      <figure>
                        <AssetImage
                          src="/assets/images/sp/gardenresort/img_activity.png"
                          alt=""
                          loading="lazy"
                        />
                      </figure>
                    ) : (
                      <figure>
                        <AssetImage
                          src="/assets/images/sp/gardenresort/img_activity__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </figure>
                    )}
                  </div>
                </div>
                <div className="lp_limitedBody">
                  <p className="lp_limitedTitle">モーニングヨガ</p>
                  <p className="lp_limitedBody">
                    やさしい動きのヨガのポーズで⾝体を温め、アクティブな⼀⽇のスタートを。アロマが⾹る空間で、⼼も⾝体も喜ぶ朝の温活ヨガをお楽しみください。
                  </p>
                  <CBtnList
                    align="left"
                    data={[
                      {
                        label: 'アクティビティの詳細はこちら',
                        link: {
                          href: 'https://www.srph.co.jp/experience/activity/dqags4obj/',
                          blank: true,
                        },
                      },
                    ]}
                  />
                </div>
              </div>
              <div className="lp_limitedUnit lp_limitedUnit__reverse">
                <div className="lp_limitedMedia">
                  <div className="imgWrapper">
                    {!isSp ? (
                      <figure>
                        <AssetImage
                          src="/assets/images/sp/gardenresort/img_activity02.png"
                          alt=""
                          loading="lazy"
                        />
                      </figure>
                    ) : (
                      <figure>
                        <AssetImage
                          src="/assets/images/sp/gardenresort/img_activity02.png"
                          alt=""
                          loading="lazy"
                        />
                      </figure>
                    )}
                  </div>
                </div>
                <div className="lp_limitedBody">
                  <p className="lp_limitedTitle">宙（ソラ）ネタリウム</p>
                  <p className="lp_limitedBody">
                    寒さが深まる時期は夜空いっぱいに美しい星空が広がるシーズン。<br /> 星のソムリエ ® の資格をもつホテルスタッフが冬ならではの星空をわかりやすく解説します。週末のステイでは冬の夜空もお楽しみください。
                  </p>
                  <CBtnList
                    align="left"
                    data={[
                      {
                        label: 'アクティビティの詳細はこちら',
                        link: {
                          href: 'https://www.srph.co.jp/experience/activity/kami79c6m/',
                          blank: true,
                        },
                      },
                    ]}
                  />
                </div>
              </div>

              <div
                className={`c_modal ${openFlag04 ? 'is_open' : ''}`}
                onClick={closeClick04}>
                <div
                  className="c_modal_innerWinter"
                  onClick={(e) => e.stopPropagation()}>
                  <div className="c_modal_innerWinterKv">
                    {!isSp ? (
                      <AssetImage
                        src="/assets/images/sp/gardenresort/img_plan_kv.png"
                        alt=""
                        loading="lazy" />
                    ) : (
                      <AssetImage
                        src="/assets/images/sp/gardenresort/img_plan_kv__sp.png"
                        alt=""
                        loading="lazy" />
                    )}
                  </div>
                </div>
                <div className='demo1'>
                  <section className="c_modal_innerWinterContents">

                    <div className='c_modal_left'>
                      <div className="c_modal_innerWinterPlanNameItem">
                        <div className="c_modal_innerWinterPlanName">
                          スプリングバレー仙台泉
                        </div>
                      </div>
                      <div className="c_modal_innerWinterPlanTitle container_modal">
                        <div className="model-left">
                          ＜実施期間＞
                        </div>
                        <div className="model-right">
                          2024年12⽉17⽇ (⽕) 〜 2025年4⽉6⽇ (⽇)
                          <br />
                          ナイター：12⽉17⽇ (⽕) 〜 3⽉8⽇ (⼟)
                          <br />
                          雪の冒険王国：12⽉21⽇ (⼟) 〜 3⽉30⽇ (⽇)
                        </div>
                      </div>
                      <div className="c_modal_innerWinterPlanDescription">
                        仙台近郊に位置する本格スキー施設「スプリングバレー仙台泉」。⼭頂からは美しい雪景⾊が広がり、ナイターでは満天の星空を堪能できます。また、雪遊びが楽しめる「雪の冒険王国」など、家族で楽しめるアクティビティも充実。この冬、特別な体験をお楽しみください。
                      </div>
                    </div>
                    <h4 className="lp_subTitle u_mb20 t_50">
                      <span className="en">benefit</span>
                      <span className="ja">ホテル優待特典</span>
                    </h4>

                    {!isSp ? (
                      <p className="u_tac_pc u_mb60">
                        ⼊場料‧リフト券など優待料⾦でご購⼊いただけます。
                      </p>
                    ) : (
                      <p className="u_tac_pc u_mb60">
                        ⼊場料‧リフト券など優待料⾦でご購⼊いただけます。
                      </p>
                    )}
                    <div className="c_modal_innerWinterPlanPointItem u_mbSmall">


                      <div className="u_tac_pc u_mb61">
                        【雪の冒険王国⼊場料】
                        <br />
                        通常料⾦1,500円⇒優待料⾦1,200円（⼤⼈/⼦供共通）
                        <br />
                        【リフト優待料⾦】
                        <br />
                        ⼤⼈6時間券通常5,300円⇒優待料⾦4,300円/⼦供6時間券通常3,900円⇒2,900円
                        <br />
                      </div>
                    </div>
                    <div className='lp_taxiBodyModal'>
                      <p className="lp_taxiTitle">＜定額料金で安心！往復タクシーのご案内＞</p>
                      <p className='lp_taxiText'>目的地への移動がもっと便利に！
                      <br className='u_pc' />移動中も快適で安心な定額料金の往復タクシーをぜひご利用ください。※事前予約制
                      </p>
                      <p className='lp_taxiTextSub'>ホテル ー スプリングバレー泉 ー ホテル　<br className='u_sp' />12,420円</p>
                    </div>
                    <div className="c_modal_innerWinterPlanPointItem">
                      <ul className="c_btnList u_mbMedium c_modal_innerWinterBtn">
                        <li className="item">
                          <CBtn
                            label={<>スプリングバレー仙台泉について</>}
                            icon="blank"
                            link={{
                              href: 'https://www.springvalley.co.jp/winter',
                              blank: true,
                            }}
                          />
                        </li>

                      </ul>
                    </div>
                    <div className="c_modal_innerWinterClose">
                      <button
                        className="c_modal_innerWinterCloseButton"
                        onClick={closeClick04}
                      >
                        <AssetImage
                          src="/assets/images/sp/gardenresort/img_modal_close.png"
                          alt=""
                          loading="lazy"
                        />
                      </button>
                    </div>
                  </section>
                </div>
              </div>
              <div
                className={`c_modal ${openFlag05 ? 'is_open' : ''}`}
                onClick={closeClick05}>
                <div
                  className="c_modal_innerWinter"
                  onClick={(e) => e.stopPropagation()}>
                  <div className="c_modal_innerWinterKv">
                    {!isSp ? (
                      <AssetImage
                        src="/assets/images/sp/gardenresort/modal_plan02_kv.png"
                        alt=""
                        loading="lazy" />
                    ) : (
                      <AssetImage
                        src="/assets/images/sp/gardenresort/modal_plan02_kv__sp.png"
                        alt=""
                        loading="lazy" />
                    )}
                  </div>
                </div>
                <div className='demo'>
                  <section className="c_modal_innerWinterContents">

                    <div className='c_modal_left'>
                      <div className="c_modal_innerWinterPlanNameItem">
                        <div className="c_modal_innerWinterPlanName">
                          いちご摘み体験
                        </div>
                      </div>
                      <div className="c_modal_innerWinterPlanTitle">
                        <div>
                          ＜実施期間＞<br className='u_sp' />2025年1月5日(日) 〜 2025年6⽉末 ※⽕曜⽇定休⽇、臨時休業あり
                        </div>
                        <div>＜ご予約＞<br className='u_sp' />前⽇15:30まで要予約</div>
                      </div>

                      <div className="c_modal_innerWinterPlanDescription">
                        仙台市内で手軽にいちご摘みをお楽しみいただける「一苺一笑（いちごいちえ）松森農場」。
                        <br />
                        とちおとめや紅ほっぺ、もういっこなど新鮮ないちごが5品種、40分間摘んで食べ放題が嬉しいポイント。
                        <br className='u_pc' />
                        品種ごとに異なる味わいをご堪能下さい。ベビーカーや車いすでも通りやすい広々とした施設なので、家族みんなでの体験におすすめです。
                      </div>
                    </div>
                    <h4 className="lp_subTitle u_mb20 t_50">
                      <span className="en">benefit</span>
                      <span className="ja">ホテル優待特典</span>
                    </h4>

                    {!isSp ? (
                      <p className="u_tac_pc u_mb60">
                        普段は予約ができない特別な体験も、ホテル経由なら予約が可能！<br />
                        また「一苺一笑（いちごいちえ）松森農場」よりお土産のプレゼントがつきます。
                      </p>
                    ) : (
                      <p className="u_tac_pc u_mb60">
                        普段は予約ができない特別な体験も、ホテル経由なら予約が可能！「一苺一笑（いちごいちえ）松森農場」よりお土産のプレゼントがつきます。
                      </p>
                    )}
                    <div className="c_modal_innerWinterPlanPointItem u_mbSmall">
                      <div className="c_modal_innerWinterPlanImg">
                        <AssetImage
                          src="/assets/images/sp/winterplan2023/modal_plan04_point.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div className="c_modal_innerWinterPlanPoint02">
                        <span className="c_modal_innerWinterPlanPoint02Title">
                          いちご摘み料金について
                        </span>
                      </div>
                      <div className="c_modal_innerWinterPlanDescription02">
                        料金は以下の通りです。
                        <br className="u_sp" />
                        ※時期によって料金が変わります。
                      </div>
                    </div>
                    <table className="c_fixedTable c_modal_innerWinterTable u_mbMedium">
                      <thead>
                        <tr>
                          <th>年齢</th>
                          <th>1/5～3/31</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            小学3年生
                            <br className="u_sp" />
                            ～64歳まで
                          </td>
                          <td>2,500円<br /></td>
                        </tr>
                        <tr>
                          <td>
                            4歳～
                            <br className="u_sp" />
                            小学2年生まで
                          </td>
                          <td>1,300円</td>
                        </tr>
                        <tr>
                          <td>65歳以上</td>
                          <td>2,300円</td>
                        </tr>
                        <tr>
                          <td>3歳以下</td>
                          <td>無料</td>
                        </tr>
                      </tbody>
                    </table>
                    <div className='lp_taxiBodyModal'>
                      <p className="lp_taxiTitle">＜定額料金で安心！往復タクシーのご案内＞</p>
                      <p className='lp_taxiText'>目的地への移動がもっと便利に！
                      <br className='u_pc' />移動中も快適で安心な定額料金の往復タクシーをぜひご利用ください。※事前予約制
                      </p>
                      <p className='lp_taxiTextSub'>ホテル ー 一苺一笑松森農場 ー ホテル　<br className='u_sp' />6,820円</p>
                    </div>
                    <div className="c_modal_innerWinterPlanPointItem">
                      <ul className="c_btnList u_mbMedium c_modal_innerWinterBtn">
                        <li className="item">
                          <CBtn
                            label={<>一苺一笑松森農場についてはこちら</>}
                            icon="blank"
                            link={{
                              href: 'https://ichiichigo.jp/',
                              blank: true,
                            }}
                          />
                        </li>
                        <li className="item btnBlew">


                          <a href="TEL:0223772111" className="c_btn c_btn_none" ><span className="label">ご予約 TEL 022-377-2111</span></a>

                        </li>
                      </ul>
                    </div>
                    <div className="c_modal_innerWinterClose">
                      <button
                        className="c_modal_innerWinterCloseButton"
                        onClick={closeClick05}
                      >
                        <AssetImage
                          src="/assets/images/sp/gardenresort/img_modal_close.png"
                          alt=""
                          loading="lazy"
                        />
                      </button>
                    </div>
                  </section>
                </div>
              </div>
              <h4 className="lp_subTitle u_mb40">
                <span className="en">Limited time</span>
                <span className="ja">【周辺アクティビティ】</span>
              </h4>

              <div className="lp_limitedUnit lp_limitedUnit02">
                <div className="lp_limitedMedia">
                  <div className="imgWrapper">
                    {!isSp ? (
                      <figure>
                        <AssetImage
                          src="/assets/images/sp/gardenresort/img_activity03.png"
                          alt=""
                          loading="lazy"
                        />
                      </figure>
                    ) : (
                      <figure>
                        <AssetImage
                          src="/assets/images/sp/gardenresort/img_plan_kv__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </figure>
                    )}
                  </div>
                </div>
                <div className="lp_limitedBody">
                  <p className="lp_limitedTitle">スプリングバレー仙台泉</p>
                  <p className="lp_limitedBody">
                    仙台近郊の本格スキー施設「スプリングバレー仙台泉」。⼭頂からの雪景⾊やナイターでの星空、家族で楽しめる「雪の冒険王国」など充実のアクティビティが魅⼒です。さらに、ホテル経由でのご利⽤なら優待料⾦でお得！宿泊と合わせて特別な冬のひとときをお楽しみください。
                  </p>

                  <CBtn
                    label={<>アクティビティの詳細はこちら</>}
                    icon="blank2"
                    tag="button"
                    onClick={openClick04}
                    link={{
                      href: '',
                    }} />
                </div>
              </div>
              <div className="lp_limitedUnit lp_limitedUnit02">
                <div className="lp_limitedMedia lp_limitedMedia_order">
                  <div className="imgWrapper">
                    {!isSp ? (
                      <figure>
                        <AssetImage
                          src="/assets/images/sp/gardenresort/img_activity04.png"
                          alt=""
                          loading="lazy"
                        />
                      </figure>
                    ) : (
                      <figure>
                        <AssetImage
                          src="/assets/images/sp/gardenresort/modal_plan02_kv__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </figure>
                    )}
                  </div>
                </div>
                <div className="lp_limitedBody">
                  <p className="lp_limitedTitle">いちご摘み体験</p>
                  <p className="lp_limitedBody">
                    仙台市内で⼿軽にいちご摘みを楽しめる「⼀苺⼀笑 松森農場」。とちおとめや紅ほっぺなど5品種を40分間摘み放題‧⾷べ放題で味わえます。 <br />普段は予約できない体験も、ホテル経由なら予約可能！さらにお⼟産の特典付きでお得に楽しめます。
                  </p>
                  <CBtn
                    label={<>アクティビティの詳細はこちら</>}
                    icon="blank2"
                    tag="button"
                    onClick={openClick05}
                    link={{
                      href: '',
                    }} />
                </div>
              </div>

              <div className="lp_subTitle02">
                【提携施設】
                <br />
                お得にリフレッシュ！提携温泉施設で心も体も温まる体験を
              </div>
              <div className="lp_limitedUnitFacility">
                <div className="lp_limitedMedia">
                  <div className="imgWrapper">
                    {!isSp ? (
                      <figure>
                        <AssetImage
                          src="/assets/images/sp/gardenresort/img_activity06.png"
                          alt=""
                          loading="lazy"
                        />
                      </figure>
                    ) : (
                      <figure>
                        <AssetImage
                          src="/assets/images/sp/gardenresort/img_activity06__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </figure>
                    )}
                  </div>
                </div>
                <div className="lp_limitedBody">
                  <p className="lp_limitedTitle">スパメッツァ仙台</p>
                  <p className="lp_limitedBody">
                  ホテルの提携施設である「スパメッツァ仙台」を優待価格でご利用できます。伊達なサウナやハーブ香る杜の潤いソルトサウナで、旅の疲れをゆったり癒して。
                  </p>
                  <CBtnList
                    align="left"
                    data={[
                      {
                        label: 'スパメッツァ仙台',
                        link: {
                          href: 'https://ryusenjinoyu.com/spametsasendai/',
                          blank: true,
                        },
                      },
                    ]}
                  />
                </div>
              </div>
              <div className='lp_taxiBody'>
                  <p className="lp_taxiTitle">＜定額料金で安心！往復タクシーのご案内＞</p>
                  <p className='lp_taxiText'>目的地への移動がもっと便利に！
                  <br className='u_pc' />移動中も快適で安心な定額料金の往復タクシーをぜひご利用ください。※事前予約制
                  </p>
                  <p className='lp_taxiTextSub'>ホテル ー スパメッツァ仙台 ー ホテル　<br className='u_sp' />2,820円</p>
              </div>

              <div className="lp_subTitle02">
                荷物をあずけ、近隣のショッピングモールで
                <br className="u_pc" />
                ⾝軽にショッピング
              </div>
              <div className="lp_lead">
                近隣の「仙台泉プレミアム‧アウトレット」や「泉パークタウン タピオ」などでショッピングを。荷物はホテルに預けて、⾝軽に周れるのが嬉しい。
                <br />
                また来たくなるような美しい街並みをたのしみながら、旅の締めくくりに。
              </div>


              <div className="lp_plan">
                <div className="lp_planList">
                  <div className="lp_planListImg">
                    {!isSp ? (
                      <AssetImage
                        src="/assets/images/sp/gardenresort/img_activity07.png"
                        alt=""
                        loading="lazy"
                      />
                    ) : (
                      <AssetImage
                        src="/assets/images/sp/gardenresort/img_activity07__sp.png"
                        alt=""
                        loading="lazy"
                      />
                    )}
                  </div>
                  <CBtnList
                    align="center"
                    data={[
                      {
                        label: '仙台泉プレミアム・アウトレット',
                        link: {
                          href: 'https://www.premiumoutlets.co.jp/sendaiizumi/',
                          blank: true,
                        },
                      },
                    ]}
                  />
                </div>
                <div className="lp_planList">
                  <div className="lp_planListImg">
                    {!isSp ? (
                      <AssetImage
                        src="/assets/images/sp/gardenresort/img_activity05.png"
                        alt=""
                        loading="lazy"
                      />
                    ) : (
                      <AssetImage
                        src="/assets/images/sp/gardenresort/img_activity05__sp.png"
                        alt=""
                        loading="lazy"
                      />
                    )}
                  </div>
                  <CBtnList
                    align="center"
                    data={[
                      {
                        label: '泉パークタウン タピオ',
                        link: {
                          href: 'https://www.tapio.jp/',
                          blank: true,
                        },
                      },
                    ]}
                  />
                </div>
              </div>



            </LWrap>
          </div>
        </main>
      </LayoutLp>
    </>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;