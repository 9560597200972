import * as React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  LPlan,
  CFloorList,
  CustomLink,
  CFixedImg,
} from '../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        data={{
          title: {
            en: 'facilities',
            ja: '館内施設',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/stay/facilities/kv.png',
              },
              imgSp: {
                src: '/assets/images/stay/facilities/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: '宿泊',
                path: '/stay/',
              },
            ],
            current: {
              label: '施設',
            },
          }}
        />
      </CJumbotron>
      <section className="l_sect02">
        <LWrap>
          <h2 className="c_headingLv2">
            日常の喧騒から離れ、
            <br className="u_sp" />
            ゆったりとリゾートステイ
          </h2>
          <p className="c_sectLead u_mbMedium">
            エステティックサロン、宴会・会議施設、アクティビティなどヨーロッパの趣と落ち着きを感じさせるリゾートホテルです。
            <br className="u_pc" />
            エステ、アクティビティなど
            色々なシーンでリゾートステイをご堪能ください。
          </p>
          <CFixedImg
            width={600}
            img={{
              src: '/assets/images/stay/facilities/img_facilities.png',
              alt: '',
            }}
          />
          <CFloorList
            data={[
              {
                title: '7F',
                list: [
                  <>
                    レストラン
                    <br />
                    <span>
                      <CustomLink to="/restaurants/keikaen/">
                        <i className="icon-simpleArrow"></i>中国料理「桂花苑」
                      </CustomLink>
                    </span>
                  </>,
                ],
              },
              {
                title: '3F',
                list: [
                  <>
                    エステ
                    <br />
                    <span>
                      <CustomLink to="/aesthetic/">
                        <i className="icon-simpleArrow"></i>アーユソーマ
                      </CustomLink>
                    </span>
                  </>,
                ],
              },
              {
                title: 'LF',
                list: [
                  <>
                    <div className="floor">
                      <div className="content">
                        <p>レストラン</p>
                        <span>
                          <CustomLink to="/restaurants/fontaine/">
                            <i className="icon-simpleArrow"></i>
                            ロビーラウンジ「フォンテーヌ」
                          </CustomLink>
                        </span>
                      </div>
                      <div>
                        <p>宴会</p>
                        <span>
                          <CustomLink to="/banquet/grand_hall/">
                            <i className="icon-simpleArrow"></i>
                            大宴会場「ロイヤルホール」
                          </CustomLink>
                        </span>
                      </div>
                    </div>
                    <br />
                    その他
                    <br />
                    <span>
                      おむつ替えコーナー ／ 授乳室 ／ 地下駐車場 ／ 喫煙スペース
                    </span>
                  </>,
                ],
              },
              {
                title: 'GF',
                list: [
                  <>
                    レストラン
                    <br />
                    <span>
                      <CustomLink to="/restaurants/chefsterrace/">
                        <i className="icon-simpleArrow"></i>
                        オールデイダイニング「シェフズ テラス」
                      </CustomLink>
                    </span>
                    <span>
                      <CustomLink to="/restaurants/nanatsumori/">
                        <i className="icon-simpleArrow"></i>鉄板焼「七つ森」
                      </CustomLink>
                    </span>
                  </>,
                  <>
                    <div className="floor">
                      <div className="content">
                        <p>ウエディング</p>
                        <span>
                          <i className="icon-simpleArrow"></i>チャペル
                        </span>
                        <span>
                          <i className="icon-simpleArrow"></i>神殿
                        </span>
                      </div>
                      <div className="content">
                        <p>美容室</p>
                        <span>
                          <CustomLink to="/stay/facilities/couturecreo/">
                            <i className="icon-simpleArrow"></i>クチュールクレオ
                          </CustomLink>
                        </span>
                      </div>
                      <div>
                        <p>写真室</p>
                        <span>
                          <CustomLink to="/stay/facilities/laviearts/">
                            <i className="icon-simpleArrow"></i>ラヴィ・アーツ
                          </CustomLink>
                        </span>
                      </div>
                    </div>
                  </>,
                  <>
                    宴会
                    <br />
                    <span>
                      <CustomLink to="/banquet/crown/">
                        <i className="icon-simpleArrow"></i>クラウンルーム
                      </CustomLink>
                    </span>
                    <span>
                      <CustomLink to="/banquet/utage/">
                        <i className="icon-simpleArrow"></i>うたげ・かすみ・あや
                      </CustomLink>
                    </span>
                    <span>
                      <CustomLink to="/banquet/mon_cheri/">
                        <i className="icon-simpleArrow"></i>
                        ガーデンバンケット「モン シェリー」
                      </CustomLink>
                    </span>
                    <span>
                      <CustomLink to="/banquet/exclusive_table/">
                        <i className="icon-simpleArrow"></i>
                        エクスクルーシブテーブル{' '}
                      </CustomLink>
                    </span>
                  </>,
                  <>
                    その他
                    <br />
                    <span>おむつ替えコーナー ／ 授乳室</span>
                  </>,
                ],
              },
            ]}
          />
          <h3 className="c_headingLv3 u_mb30">その他施設</h3>
          <div className="u_colorGray03 c_labelItems">
            <div className="c_labelItem">
              <span className="c_label">LF-GF</span>
              <p>おむつ替えコーナー</p>
            </div>
            <div className="c_labelItem">
              <span className="c_label c_label03">LF</span>
              <p>喫煙スペース</p>
            </div>
            <div className="c_labelItem">
              <span className="c_label c_label03">LF</span>
              <p>地下駐車場出入り口</p>
            </div>
            <div className="c_labelItem">
              <span className="c_label">LF-GF</span>
              <p>授乳室</p>
            </div>
            <div className="c_labelItem">
              <span className="c_label c_label03">LF</span>
              <p>バルコニー</p>
            </div>
          </div>
        </LWrap>
      </section>
      <LPlan />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
