import * as React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  LPlan,
  CSectTitle,
  CSimpleAccordionList,
  CDefinition,
  CBtnList,
  CAnchorNav,
  CMedia,
  CHeroMedia,
  CHeroCard,
  CBorderTitle,
  CSimpleCard,
  CSpTabTable,
  CPostCard02,
  CHeroImg,
  CSliderMedia,
  CTab,
  CTabItem,
  AssetImage,
} from '../../components/_index';
import planChoice from '../../utils/plan-choice';
import planGet from '../../utils/plan-get';
import '../../assets/_sass/page/aesthetic.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            en: 'aesthetic',
            ja: 'エステ',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/aesthetic/kv.png',
              },
              imgSp: {
                src: '/assets/images/aesthetic/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [],
            current: {
              label: 'エステ',
            },
          }}
        />
      </CJumbotron>
      <section>
        <LWrap>
          <CAnchorNav
            exClass="p_aesthetic_nav"
            data={[
              {
                label: 'アーユソーマの魅力',
                link: {
                  href: '/aesthetic#room',
                },
              },
              {
                label: 'メニュー',
                link: {
                  href: '/aesthetic#menu',
                },
              },
              {
                label: 'プラン',
                link: {
                  href: '/aesthetic#plan',
                },
              },
              {
                label: '宿泊者限定',
                link: {
                  href: '/aesthetic#guest-benefits',
                },
              },
              {
                label: '営業時間',
                link: {
                  href: '/aesthetic#guide',
                },
              },
              {
                label: 'プリペイドチケット',
                link: {
                  href: '/aesthetic#pre-paid-ticket',
                },
              },
            ]}
          />
        </LWrap>
      </section>
      <section id="room" className="l_sect02">
        <LWrap>
          <figure className="imgWrapper u_tac u_mb60">
            <AssetImage
              src="/assets/images/aesthetic/img_ayusoma_logo.svg"
              alt=""
            />
          </figure>
          <h2 className="c_headingLv2">
            癒やしの先の健康美
            <br />
            リゾートで満喫するちょっと贅沢なエステ
          </h2>
          <CMedia
            exClass=""
            data={[
              {
                img: {
                  src: '/assets/images/aesthetic/img_aesthetic.png',
                  alt: '',
                },
                title: '',
                text: (
                  <>
                    インド伝承医学である「アーユルヴェーダ」。アーユソーマでは、体調や体質に合わせた自然のオイルなどを使いながら、心身を健康に導いていく「アーユルヴェーダ」の考え方に基づいたプログラムをご提供します。
                  </>
                ),
              },
              {
                img: {
                  src: '/assets/images/aesthetic/img_aesthetic02.png',
                  alt: '',
                },
                title: '',
                text: (
                  <>
                    ”健やかな身体にこそ美しさは宿る”という理念のもと、本来持っている美しさを引き出す癒しのエステ。非日常のリゾート空間で、ぜひご体験ください。
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <section id="menu" className="u_mbLarge u_mtLarge">
        <LWrap>
          <CSectTitle
            title={{
              ja: 'メニュー',
              en: <>MENU</>,
            }}
          />
          <CHeroImg
            exClass="u_mbMedium"
            img={{
              src: '/assets/images/aesthetic/img_aesthetic10.png',
              alt: '',
            }}
          />
          <div className="aesthetic_menu">
            <h2 className="c_sectTitle02 ">
              <span className="ja">アーユルヴェーダ</span>
              <span wovn-ignore="true" className="en">
                AYURVEDA
              </span>
            </h2>
            <div className="text">
              インド古来より受け継がれている伝承医学のアーユルヴェーダを取り入れたトリートメント。
              <br />
              丁寧なカウンセリングで最適なメニューをご提案します。
            </div>
            <div className="btn">
              <a href="/aesthetic/menu/" className="c_btn c_btn__borderTheme  ">
                <span className="label">アーユルヴェーダメニュー</span>
                <i className="icon-arrow"></i>
              </a>
            </div>
          </div>
        </LWrap>
      </section>
      <section className="u_mbLarge">
        <LWrap>
          <CHeroCard
            exClass=""
            data={[
              {
                title: {
                  ja: 'スンダリ',
                  en: 'SUNDÃRI',
                },
                img: {
                  src: '/assets/images/aesthetic/img_aesthetic04.png',
                  alt: '',
                },
                text: (
                  <>
                    アーユルヴェーダを現代人のニーズに合わせたニューヨーク生まれのスキンケアブランド「SUNDARI」を用いた様々なメニューをご提供します。
                  </>
                ),
                btn: {
                  label: 'スンダリメニュー',
                  link: {
                    href: '/aesthetic/menu/#sundari',
                  },
                },
              },
              {
                title: {
                  ja: 'その他',
                  en: 'OTHER',
                },
                img: {
                  src: '/assets/images/aesthetic/img_aesthetic05.png',
                  alt: '',
                },
                text: (
                  <>
                    様々なトリートメントメニューを取り扱っております。体の気になる部分を集中してトリートメントされたい方におすすめです。
                  </>
                ),
                btn: {
                  label: 'その他メニュー',
                  link: {
                    href: '/aesthetic/menu/#others',
                  },
                },
              },
            ]}
          />
        </LWrap>
      </section>
      <section id="plan" className="l_sect02">
        <LWrap>
          <CSectTitle
            title={{
              ja: 'プラン',
              en: <>PLAN</>,
            }}
          />
          <p className="c_sectLead u_mbMedium">
            リゾートホテルを満喫できる
            <br />
            女性にうれしいエステプラン
          </p>
          <CPostCard02 data={planChoice(planGet(), ['エステ'], 0)} />
          <ul className="c_noteList">
            <li>掲載の内容は予告なく変更する場合があります。</li>
            <li>画像はイメージです。</li>
          </ul>
        </LWrap>
      </section>
      <section id="guest-benefits" className="l_sect u_bgGray">
        <LWrap>
          <CSectTitle
            title={{
              ja: '宿泊者限定特典',
              en: (
                <>
                  GUEST
                  <span className="u_pc"> </span>
                  <br className="u_sp" />
                  BENEFITS
                </>
              ),
            }}
          />
          <p className="c_sectLead u_mbMedium">
            アーユソーマではお気軽にエステをご利用いただけるよう、
            <br />
            ご宿泊者限定の特典をご用意しております。
          </p>

          <div
            className="u_bgWhite c_simpleAccordionList aesthetic"
            style={{ padding: '56px', border: 'none' }}
          >
            <CBorderTitle
              size="small"
              title={<>宿泊者限定特典</>}
              exClass="n_mt"
            />
            <p className="title">全グランドメニュー20%OFF</p>
            <p className="text">
              ご宿泊のお客様（女性限定）は、アーユソーマの全グランドメニューを「20％OFF」でご利用いただけます。
            </p>
            <CBtnList
                data={[
                  {
                    label: 'グランドメニューはこちら',
                    link: {
                      href: '/assets/files/pdf/ayu_grandmenu.pdf',
                    },
                  },
                ]}
              />

            <p className="sp_title u_sp u_pt40">シーズナルエステ特別価格</p>
            <CSliderMedia
              reverse={true}
              data={[
                {
                  img: [
                    {
                      img: {
                        src: '/assets/images/aesthetic/img_aesthetic12.png',
                        alt: 'エステ',
                      },
                    },
                  ],
                  title: <></>,
                  text: (
                    <>
                      <p className="title">シーズナルエステ特別価格</p>
                      <p className="text">
                      ご宿泊のお客様（女性のお客様限定）はシーズナルエステを特別価格でご利用いただけます。詳しくはエステプラン「シーズナルエステ」をご覧ください。
                      <br />
                      ※こちらは2025年1月3日（金）～2025年2月28日（金）までの特典となります。
                      </p>
                    </>
                  ),
                  btn: {
                    link: {
                      href: '/aesthetic/pzd09bpe99uj/',
                    },
                    label: '詳しくはこちら',
                  },
                },
              ]}
            />

            <p className="sp_title u_sp u_pt40">宿泊者限定アニバーサリーエステプラン</p>
            <CSliderMedia
              reverse={true}
              data={[
                {
                  img: [
                    {
                      img: {
                        src: '/assets/images/aesthetic/img_aesthetic13.png',
                        alt: 'エステ',
                      },
                    },
                  ],
                  title: <></>,
                  text: (
                    <>
                      <p className="title">宿泊者限定アニバーサリーエステプラン</p>
                      <p className="text">
                      お誕生日や結婚記念日など、一年に一度のアニバーサリーを贅沢な気分でお過ごしいただけるようご宿泊者限定のアニバーサリーエステプランをご用意しております。
                      </p>
                    </>
                  ),
                  btn: {
                    link: {
                      href: '/aesthetic/lhqkeot5ya3p/',
                    },
                    label: '詳しくはこちら',
                  },
                },
              ]}
            />
          </div>
        </LWrap>
      </section>
      <section id="guide" className="l_sect">
        <LWrap>
          <CSectTitle
            title={{
              ja: '営業時間｜ご利用案内',
              en: <>GUIDE</>,
            }}
          />
          <CDefinition
            data={[
              {
                title: <>場所</>,
                text: <>仙台ロイヤルパークホテル3F</>,
              },
              {
                title: <>営業時間</>,
                text: <>
                日曜日 ～ 木曜日 ／ 10:00～19:30
                <br />
                金曜日・土曜日・祝前日 ／ 10:00～21:00
                </>,
              },
              {
                title: <>ご予約について</>,
                text: (
                  <>
                    事前予約制
                  </>
                ),
              },
              {
                title: <>対象者</>,
                text: (
                  <>
                    18歳以上の女性の方
                    <ul className="c_noteList">
                      <li>
                        ペアトリートメントプランのみ、男女でのご利用が可能です。
                      </li>
                    </ul>
                  </>
                ),
              },
              {
                title: <>会員特典</>,
                text: (
                  <>
                    「ザ
                    クラブ・ロイヤルパークホテルズ」会員の方は、一般価格から5%OFFの会員優待価格にてご利用いただけます。会員ステージに応じたポイントも付与されます。
                  </>
                ),
              },
              {
                title: <>禁忌事項</>,
                text: (
                  <>
                    妊娠・心臓疾患・循環器系障害・静脈瘤・皮膚疾患・体調不良、その他持病のある方や医師による治療を受けている方は、トリートメントをお受けいただけませんのでご了承ください。
                    <br />
                    また、アルコール摂取後のご利用もお控えいただきますようお願いいたします。詳しくはお問合せください。
                  </>
                ),
              },
              {
                title: <>キャンセル料</>,
                text: (
                  <>
                    ご予約の変更、キャンセルにつきましては前日18時までにご連絡ください。
                    <ul className="c_noteList">
                      <li>
                        当日キャンセルの場合、キャンセル料として50%を頂戴します。
                      </li>
                    </ul>
                  </>
                ),
              },
              {
                title: <>お問合せ</>,
                text: (
                  <>
                    <a href="tel:0223773896">022-377-3896</a>
                    <br />
                    （受付時間10:00～18:00）
                    <br />
                    ※18:00～翌10:00は、022-377-1111までご連絡ください。
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <section
        id="pre-paid-ticket"
        className="l_sect c_simpleAccordionList aesthetic"
      >
        <LWrap>
          <CSectTitle
            title={{
              ja: 'プリペイドチケット',
              en: (
                <>
                  PRE-PAID
                  <span className="u_pc"> </span>
                  <br className="u_sp" />
                  TICKET
                </>
              ),
            }}
          />
          <p className="c_sectLead u_mbMedium">
            アーユソーマでは、お得なプリペイドチケットをご用意しております。
            <br />
            継続してご利用いただきやすいプリペイドチケットをご活用ください。
          </p>
          <div className="u_pc">
            <CSpTabTable
              data={{
                header: ['Aプラン', 'Bプラン', 'Cプラン'],
                key: ['料金', '有効期間', '優待①', '優待②'],
                items: [
                  {
                    content: [
                      '150,000円',
                      '2年間',
                      '全グランドメニューをプリペイドチケット価格で利用可能',
                      'グランドメニュー60分（18,000円コース相当）の1回分無料チケットプレゼント（ご本人様もしくはご家族・ご友人の方へ譲渡可能）',
                    ],
                  },
                  {
                    content: [
                      '75,000円',
                      '1年間',
                      '全グランドメニューをプリペイドチケット価格で利用可能',
                      <>
                        エステオプション「パーツトリートメント」プレゼント
                        <div className="note">30分：5,000円相当</div>
                      </>,
                    ],
                  },
                  {
                    content: [
                      '45,000円',
                      '半年間',
                      '全グランドメニューをプリペイドチケット価格で利用可能',
                      '-',
                    ],
                  },
                ],
              }}
            />
          </div>
          <div className="u_sp tab">
            <CTab defaultKey={0}>
              <CTabItem title="Aプラン" tabKey={0}>
                <div className="tab_item">
                  <p className="title">料金</p>
                  <p className="price">150,000円</p>
                </div>
                <div className="tab_item">
                  <p className="title">有効期間</p>
                  <p className="price">2年間</p>
                </div>
                <div className="tab_item">
                  <p className="title">優待①</p>
                  <p className="price">
                    全グランドメニューをプリペイドチケット価格で利用可能
                  </p>
                </div>
                <div className="tab_item">
                  <p className="title">優待②</p>
                  <p className="price">
                    グランドメニュー60分（18,000円コース相当）の1回分無料チケットプレゼント（ご本人様もしくはご家族・ご友人の方へ譲渡可能）
                  </p>
                </div>
              </CTabItem>
              <CTabItem title="Bプラン" tabKey={1}>
                <div className="tab_item">
                  <p className="title">料金</p>
                  <p className="price">75,000円</p>
                </div>
                <div className="tab_item">
                  <p className="title">有効期間</p>
                  <p className="price">1年間</p>
                </div>
                <div className="tab_item">
                  <p className="title">優待①</p>
                  <p className="price">
                    全グランドメニューをプリペイドチケット価格で利用可能
                  </p>
                </div>
                <div className="tab_item">
                  <p className="title">優待②</p>
                  <p className="price">
                    エステオプション「パーツトリートメント」プレゼント
                    <div className="note">30分：5,000円相当</div>
                  </p>
                </div>
              </CTabItem>
              <CTabItem title="Cプラン" tabKey={2}>
                <div className="tab_item">
                  <p className="title">料金</p>
                  <p className="price">45,000円</p>
                </div>
                <div className="tab_item">
                  <p className="title">有効期間</p>
                  <p className="price">半年間</p>
                </div>
                <div className="tab_item">
                  <p className="title">優待①</p>
                  <p className="price">
                    全グランドメニューをプリペイドチケット価格で利用可能
                  </p>
                </div>
                <div className="tab_item">
                  <p className="title">優待②</p>
                  <p className="price">-</p>
                </div>
              </CTabItem>
            </CTab>
          </div>
        </LWrap>
      </section>
      <LPlan />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
