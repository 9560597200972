import * as React from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CAnchorNav,
  CFaqList,
  LPlan,
} from '../../components/_index';
import { setLang } from '../../utils/set-lang';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const lang = setLang();

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        data={{
          title: {
            en: 'faq',
            ja: 'よくあるご質問',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/faq/kv.png',
              },
              imgSp: {
                src: '/assets/images/access/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [],
            current: {
              label: 'よくあるご質問',
            },
          }}
        />
      </CJumbotron>
      <div className="l_sect02 u_pt0_sp">
        <LWrap>
          <CAnchorNav
            data={[
              {
                link: {
                  href: '/faq#stay',
                },
                label: '宿泊について',
              },
              {
                link: {
                  href: '/faq#restaurant',
                },
                label: 'レストランについて',
              },
              {
                link: {
                  href: '/faq#access',
                },
                label: 'アクセスについて',
              },
            ]}
          />
        </LWrap>
      </div>
      <section className="u_mbExLarge" id="stay">
        <LWrap>
          <h2 className="c_headingLv2">宿泊について</h2>
          <CFaqList
            hash=""
            data={[
              {
                q: 'チェックイン・チェックアウトは何時ですか?',
                a: (
                  <>
                    チェックインは15:00から、チェックアウトは11:00まででございます。
                    <br />
                    （プランにより異なる場合がございますので、ご予約のプラン内容をご確認ください。）

                  </>
                ),
              },
              {
                q: 'アーリーチェックインはできますか？',
                a: (
                  <>
                    チェックインご希望の時刻に必ずお部屋のご用意が必要な場合、前日からお部屋を押さえるため原則として別途1泊分の料金がかかります。
                    <br />
                    このため前日の稼働状況によってはご利用いただけない場合もございます。詳細はホテルまでお問合せください。

                  </>
                ),
              },
              {
                q: 'レイトチェックアウトはできますか？',
                a: (
                  <>
                    チェックアウト時刻の延長は以下の追加料金を頂戴いたします。
                    <br />
                    A. 11:00～14:00迄のチェックアウト（超過3時間まで）<br />
                    予約金額または正規料金の20％<br />
                    B.14：00~15：00迄のチェックアウト（超過4時間まで）<br />
                    予約金額または正規料金の30％<br />
                    C.15：00~16：00迄のチェックアウト（超過5時間まで）<br />
                    予約金額または正規料金の40％<br />
                    D.16：00~17：00迄のチェックアウト（超過6時間まで）<br />
                    予約金額または正規料金の50％<br />
                    E.17：00を超える場合（超過6時間を超える場合）<br />
                    予約金額または正規料金の100％<br />
                  </>
                ),
              },
              {
                q: '到着が遅れそうな場合（遅れる場合）は連絡した方がいいですか？規定はありますか？',
                a: (
                  <>
                    事前にお知らせいただいたご到着予定時刻より２時間以上遅れる場合は、お電話にてご一報をお願いいたしております。お客様からのご連絡がない場合、宿泊日当日の20:00（あらかじめ到着予定時刻が明示されている場合は、その時刻を２時間経過した時刻）になっても到着されない場合は、その宿泊契約はお客様により解除されたものとみなし処理する場合がございます。
                  </>
                ),
              },
              {
                q: 'キャンセル料はかかりますか?',
                a: (
                  <>

                    以下のキャンセル料を頂戴いたします。     <br />
                    ～8日前…不要     <br />
                    7～2日前…宿泊料金の40%     <br />
                    前日…宿泊料金の80%     <br />
                    当日…宿泊料金の100%     <br />
                    不泊…宿泊料金の100%     <br />
                    ※団体予約、旅行代理店、その他エージェントを経由した場合は上記の限りではございません。     <br />
                    また、宿泊プランによって異なる場合がございますので、ご予約時にご確認ください。

                  </>
                ),
              },
              {
                q: '事前に荷物を送ることはできますか?',
                a: (
                  <>
                    ご宿泊者名・ご宿泊日を伝票に明記の上、下記までお送りください。<br />
                    〒981-3204<br />
                    仙台市泉区寺岡6-2-1<br />
                    仙台ロイヤルパークホテル フロント気付+「ご宿泊者名（カタカナ）・ご宿泊日」<br />
                    TEL 022-377-1111（代表）<br />
                    ※お荷物はご予約後にお送りいただきますようお願いいたします。<br />
                    ※着払いはお受けできません。<br />
                    ※発送された際のお控えはお手元にお持ちください。<br />

                  </>
                ),
              },

              {
                q: 'チェックイン前やチェックアウト後でも荷物を預かってくれますか？',
                a: (
                  <>
                    当日早めにご到着の場合、チェックイン前でもお荷物をお預かりいたします。お気軽にフロントスタッフへお声がけください。<br />
                    また、チェックアウト後も当日中に限りお預かりいたしますが、ご貴重品は必ずご自身でお持ちください。
                  </>
                ),
              },
              {
                q: '未成年者だけで宿泊できますか？',
                a: (
                  <>
                    未成年の方だけでご宿泊する場合は、保護者の方の同意が必要です。<br />
                    下記より同意書をダウンロードいただき、ご記入・捺印の上、チェックインの際にご提出ください。<br />
                    <Link to="/assets/files/pdf/agreement_underage.pdf" blank="true">
                      保護者同意書
                    </Link>
                  </>
                ),
              },

              {
                q: '1室何名まで泊まれますか？',
                a: (
                  <>
                    客室タイプにより異なります。各客室ページの「添い寝を含めた最大人数」をご参照ください。<br />

                    <Link to="/stay/guestroom/">
                      客室のご案内
                    </Link>
                  </>
                ),

              },

              {
                q: 'お庭を眺められるお部屋はありますか？',
                a: (
                  <>
                    ガーデンに面した南向きの客室からご覧いただけます。下記をご参照ください。
                    <br />
                    <Link to="/stay/guestroom/#gardenside">
                      ガーデンサイドのお部屋のご案内
                    </Link>
                  </>
                ),
              },

              {
                q: '喫煙ルームはありますか？',
                a: (
                  <>
                    喫煙ルームはございません。喫煙をご希望のお客様は、ロビーの喫煙所をご利用ください。

                  </>
                ),
              },

              {
                q: '車椅子で利用できるお部屋はありますか？',
                a: (
                  <>
                    ラグジュアリーツイン（37㎡）と同じ広さのユニバーサルルーム（バリアフリー）が1室ございます。<br />
                    浴室・化粧室の造りが異なり、車椅子のまま入室いただけます。<br />

                    <Link to="/stay/guestroom/universalroom/">
                      ユニバーサルルームのご案内
                    </Link>

                  </>
                ),
              },



              {
                q: 'コネクティングルームはありますか？',
                a: (
                  <>
                    ラグジュアリーファミリールーム（37㎡）・ラグジュアリーツイン（37㎡）をコネクティングルームとしてご利用いただけます。<br />


                    <Link to="/stay/guestroom/luxuryfamilyroom/">
                      ラグジュアリーファミリールームのご案内
                    </Link><br />
                    <Link to="/stay/guestroom/luxurytwin/">
                      ラグジュアリーツインのご案内
                    </Link>

                  </>
                ),
              },

              {
                q: 'ツインのお部屋のベッドは2台横並びでつけられますか？',
                a: (
                  <>
                    スタンダードハリウッドツイン（23㎡）の客室は、ベッドが2台隣接しています。またラグジュアリーファミリールーム（37㎡）はベッドが3台隣接しています。<br />


                    <Link to="/stay/guestroom/standardhollywoodtwin/">
                      スタンダードハリウッドツインのご案内
                    </Link><br />
                    <Link to="/stay/guestroom/luxuryfamilyroom/">
                      ラグジュアリーファミリールームのご案内
                    </Link>

                  </>
                ),
              },

              {
                q: 'アメニティは何がありますか？',
                a: (
                  <>
                    アメニティは、シャンプー・コンディショナー・ハブラシ・ボディソープ・ハンドソープ・シェーバー・ヘアブラシ・使い捨てスリッパ・コットン・綿棒セット、客室設備・備品として、空気洗浄加湿器・空調設備・ウォッシュレット・セーフティーボックス・シューシャインクロス・シューホーン・洋服ブラシ・ドライヤー・電気ポット・紅茶・緑茶・ルームウェアをご用意いたしております。
                  </>
                ),
              },

              {
                q: 'スキンケアセットはありますか？',
                a: (
                  <>
                    フロントにて販売いたしております。また客室タイプによりロクシタンアメニティ、スキンケアセットをご用意しているお部屋もございます。
                  </>
                ),
              },
              {
                q: 'ルームウェアーはありますか？',
                a: (
                  <>
                    各客室にご用意しております。
                  </>
                ),
              },
              {
                q: 'ルームサービスはありますか？',
                a: (
                  <>
                    申し訳ございませんが、ルームサービスは承っておりません。
                  </>
                ),
              },

              {
                q: 'DVD、ブルーレイは視聴可能でしょうか？ディスクの貸し出しはありますか？',
                a: (
                  <>
                    DVD,ブルーレイプレーヤーの貸し出しがございますが、ディスクの貸し出しはございません。<br />
                    プレーヤーは数に限りがございますので、ご予約時にお申し付けください。
                  </>
                ),
              },

              {
                q: 'ペットの同伴は可能ですか？',
                a: (
                  <>
                    犬、猫、小鳥等動物、ペット類全般のご同伴はご遠慮ください。
                  </>
                ),
              },

              {
                q: '車で行きたいのですが、駐車場はありますか？',
                a: (
                  <>
                    ホテル敷地内に無料の平面、及び地下駐車場がございます。（ホテル地下駐車場内にはEV・PHV用充電器対応（有料）、テスラ専用超急速充電器「スーパーチャージャーステーション」もございます）<br />
                    なお、ホテル地下駐車場にはエレベーターがございませんので予めご了承ください。<br />

                    詳しくはこちらをご覧ください。 <Link to="/access/#parking_access">
                      無料駐車場のご案内
                    </Link>


                  </>
                ),
              },
              {
                q: '送迎バスはありますか？',
                a: (
                  <>
                    無料シャトルバスを運行（予約不要）しております。仙台駅（東口）観光・送迎バス乗り場からご乗降ください。<br />
                    詳しくはこちらをご覧ください。

                    <Link to="/access/#bus">
                      無料シャトルバスのご案内
                    </Link>


                  </>
                ),
              },

              {
                q: '電子レンジはありますか？',
                a: (
                  <>
                    ロビーフロア（ウェディングサロン隣）に設置しております。

                  </>
                ),
              },


              {
                q: '自動販売機はありますか？',
                a: (
                  <>
                    ロビーフロア（ウェディングサロン隣）に、食品・ソフトドリンク・酒類の自動販売機を設置しております。<br />
                    ※酒類の販売にはカードキーが必要となりますので、フロントスタッフまでお申しつけください。

                  </>
                ),
              },

              {
                q: '子供料金はありますか？',
                a: (
                  <>
                    未就学のお子様がベッドを使用しない（添い寝）場合、宿泊料金は基本かかりません。（※ただし、お食事をご希望の場合は別途申し受けます。）<br />
                    なお、小学生以上のお子様については、大人と同額の宿泊料金がかかります。<br />
                    <br />
                    1．未就学のお子様について<br />
                      (1) 添い寝のお子様連れの場合<br />
                      添い寝のお子様は人数に含まず、別途ご質問・ご要望欄に添い寝のお子様がいらっしゃる旨をご記入ください。<br />
                      （添い寝をされるお子様はベッド1台につき、お一人様といたします。）<br />
                      <br />
                    2．小学生以上のお子様について<br />
                    小学生以上のお子様に関しては、大人と同様の料金を頂戴いたしますので、お子様を含んだ人数を一室「ご利用人数」入力欄へ入力してください。
                  </>
                ),
              },
              {
                q: 'ベビーベッドは借りられますか？',
                a: (
                  <>
                    ベビーベッドやベッドガードは無料でご用意いたします。数に限りがございますので、ご予約時にお申しつけください。<br />
                    なお、安全上の理由により、ベビーベッドのご利用は生後24か月以内、ベッドガードのご利用は生後18か月以上~60か月（満5歳まで）に限らせていただいております。<br />
                    ※18か月未満の乳児にはベッドガードはご使用いただけません。<br />
                    設置可能な客室はお問合せください。（一部ベビーベッドを設置できない客室もございます。）
                  </>
                ),
              },

              {
                q: '館内に授乳室・おむつ替えコーナーはありますか？',
                a: (
                  <>
                    ロビーフロア、及びガーデンフロアにおむつ替えコーナーと授乳室がございます。

                    <Link to="/stay/facilities/">
                      館内施設のご案内
                    </Link>
                  </>
                ),
              },
              {
                q: '支払い方法について教えてください',
                a: (
                  <>
                    当ホテルではチェックイン時にご精算いただき、<br />
                    追加ご利用についてはチェックアウト時にお支払をお願いいたします。

                  </>
                ),
              },
              {
                q: '使えるクレジットカードや二次元バーコード決済の種類を教えてください',
                a: (
                  <>
                    デビットカード及び、以下のクレジットカードと二次元バーコード決済をご利用いただけます。<br />
                    ＜クレジットカード＞<br />
                    American Express／VISA／DC／Diners／JCB／Master／UFJ／Nicos／銀聯カード<br />
                    ＜二次元バーコード決済＞<br />
                    PayPay/auPay/MerPay/D払い/楽天ペイ/Alipay/WeChatPay/LINEPay





                  </>
                ),
              },
              {
                q: 'ギフト券は使えますか？',
                a: (
                  <>
                    以下のギフト券をご利用いただけます。<br />
                    三菱UFJニコスギフトカード／JCBギフトカード／三菱地所グループ共通ギフトカード／ロイヤルパークホテルズギフト券<br />
                    お支払いの際にホテルスタッフへお申し付けください。
                  </>
                ),
              },


            ]}
          />
        </LWrap>
      </section>
      <section className="u_mbExLarge" id="restaurant">
        <LWrap>
          <h2 className="c_headingLv2">レストランについて</h2>
          <CFaqList
            hash=""
            data={[
              {
                q: '予約は必要ですか?',
                a: (
                  <>
                    ランチおよびディナーメニューは一部を除き完全予約制ではないためフリーでのご利用も可能ですが、お日にちによっては混み合うことがございます。予定がお決まりの場合は、早めのご予約をおすすめいたします。
                  </>
                ),
              },
              {
                q: 'アレルギーメニューには対応してもらえますか？',
                a: (
                  <>
                    一部のメニューにつきましては、ご要望に応じて、【アレルゲン特定原材料8品目】を使用せずにご用意させていただきます。詳しくは
                    <Link to="/information/v5cqvrgk3p/">
                      こちら
                    </Link> からご確認ください。　<br />
                    ※すべてのアレルギー等に対応できるわけではございませんので、予めご了承ください。
                  </>
                ),
              },




              {
                q: '誕生日などの記念日に利用したいのですが、何か特別な演出をお願いできますか?',
                a: (
                  <>
                    当ホテルでは、お客様の記念日がより良い日になるようお手伝いをさせていただいております。ケーキや花束のお手配、サプライズ演出などご要望がございましたら、お気軽にご相談ください。
                  </>
                ),
              },
              {
                q: 'レストランのオープン時間を教えてください。',
                a: (
                  <>
                    各レストランページをご参照ください。
                    <br />
                    <Link to="/restaurants/">営業時間のご案内</Link>
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="u_mbExLarge" id="access">
        <LWrap>
          <h2 className="c_headingLv2">アクセスについて</h2>
          <CFaqList
            hash=""
            data={[
              {
                q: '駐車場はありますか?また、駐車料金はいくらですか?',
                a: (
                  <>
                    ホテル敷地内の平面駐車場と地下駐車場をご利用いただけます。また、満車時は敷地外にある臨時駐車場をご案内する場合もございます。これらの駐車場は、ご滞在中は無料です。詳しくはアクセスページをご参照ください。
                    <br />
                    <Link to="/access/">アクセスページ</Link>
                  </>
                ),
              },
              {
                q: '送迎バスはありますか?',
                a: (
                  <>
                    仙台駅と当ホテル間を運行するホテル専用シャトルバスがございます。
                    <br />
                    <Link to="/access/#bus">アクセスページ</Link>
                  </>
                ),
              },
              {
                q: '仙台駅からのタクシー料金は?また最寄の泉中央駅からのタクシー料金は?',
                a: (
                  <>
                    当日の道路状況により誤差はございますが、仙台駅から約4,000円前後（所要時間約30分）、地下鉄泉中央駅からは約2,200円前後（所要時間約15分）が目安となります。
                  </>
                ),
              },
              {
                q: '有名観光地までの所要時間やアクセスを教えてください。',
                a: (
                  <>
                    松島・平泉・山寺・蔵王がお車で1時間ほどの距離にあります。高速道路のインターチェンジも近くにあり、お車での移動に便利です。
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <LPlan />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
