import * as React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  LPlan,
  CSectTitle,
  CSimpleAccordionList,
  CBorderTitle,
  CSimpleCard,
  CDefinition,
  CBtnList,
  CPostCard02,
  CMenuMedia,
  CSpTabTable,
  CColorCard,
  CHeroImg,
  CInlineDefinition,
  CSectTitle02,
  CTabItem,
  CTab,
  CSliderMedia,
} from '../../../components/_index';
import planChoice from '../../../utils/plan-choice';
import planGet from '../../../utils/plan-get';
import '../../../assets/_sass/page/aesthetic.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        data={{
          title: {
            en: 'aesthetic menu',
            ja: 'エステメニュー',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/aesthetic/menu/kv.png',
              },
              imgSp: {
                src: '/assets/images/aesthetic/menu/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: 'エステ',
                path: '/aesthetic/',
              },
            ],
            current: {
              label: 'エステメニュー',
            },
          }}
        />
      </CJumbotron>
      <section className="l_sect">
        <LWrap>
          <CSectTitle
            title={{
              ja: 'アーユルヴェーダ',
              en: <>AYURVEDA</>,
            }}
          />
          <p className="c_sectLead u_mbMedium">
            インド古来より受け継がれている伝承医学のアーユルヴェーダを取り入れたトリートメント。
            <br />
            丁寧なカウンセリングで最適なメニューをご提案します。
          </p>
          <CMenuMedia
            data={[
              {
                img: {
                  src: '/assets/images/aesthetic/menu/img_aesheticmenu.png',
                  alt: '',
                },
                title: {
                  ja: 'アヴィヤンガ',
                  en: 'ABHYANGA',
                },
                text: (
                  <>
                    一人ひとりのエネルギーバランスに合わせた体質別薬草オイルを使用するアーユルヴェーダの全身オイルトリートメント。
                    <br />
                    独自のタッチで心と身体を満たします。
                  </>
                ),
                definition: [
                  {
                    title: '施術時間：',
                    text: <>60分</>,
                  },
                  {
                    title: '価格：',
                    text: (
                      <>
                        ＜一般＞ 18,000円
                        <br />
                        ＜会員＞ 17,100円 + ポイント5%〜
                        <br />
                        ＜プリペイド＞ 14,400円
                      </>
                    ),
                  },
                ],
                btn: {
                  label: '予約する',
                  link: {
                    href: 'https://booking.ebica.jp/webrsv/plan/e014024501/12254/71691',
                    blank: true,
                  },
                  color: 'bgGreen',
                },
              },
              {
                img: {
                  src: '/assets/images/aesthetic/menu/img_aesheticmenu02.png',
                  alt: '',
                },
                title: {
                  ja: 'シローダーラー',
                  en: 'SHIRODARA',
                },
                text: (
                  <>
                    デコルテ＆ヘッドトリートメントの後、温めたオイルを額の「第3の目」に垂らし続けることで深いリラクゼーションへと導きます。ストレス緩和や眼精疲労などをケアします。
                  </>
                ),
                definition: [
                  {
                    title: '施術時間：',
                    text: <>45分</>,
                  },
                  {
                    title: '価格：',
                    text: (
                      <>
                        ＜一般＞ 18,000円
                        <br />
                        ＜会員＞ 17,100円 + ポイント5%〜
                        <br />
                        ＜プリペイド＞ 14,400円
                      </>
                    ),
                  },
                ],
                btn: {
                  label: '予約する',
                  link: {
                    href: 'https://booking.ebica.jp/webrsv/plan/e014024501/12254/71692',
                    blank: true,
                  },
                  color: 'bgGreen',
                },
              },
              {
                img: {
                  src: '/assets/images/aesthetic/menu/img_aesheticmenu03.png',
                  alt: '',
                },
                title: {
                  ja: 'アヴィヤンガ&シローダーラー',
                  en: (
                    <>
                      ABHYANGA & <br className="u_sp" />
                      SHIRODARA
                    </>
                  ),
                },
                text: (
                  <>
                    アヴィヤンガとシローダーラーを組み合わせたスペシャルコースです。
                  </>
                ),
                definition: [
                  {
                    title: '施術時間：',
                    text: <>100分</>,
                  },
                  {
                    title: '価格：',
                    text: (
                      <>
                        ＜一般＞ 30,000円
                        <br />
                        ＜会員＞ 28,500円 + ポイント5%〜
                        <br />
                        ＜プリペイド＞ 24,000円
                      </>
                    ),
                  },
                ],
                btn: {
                  label: '予約する',
                  link: {
                    href: 'https://booking.ebica.jp/webrsv/plan/e014024501/12254/71693',
                    blank: true,
                  },
                  color: 'bgGreen',
                },
              },
            ]}
          />
          <h2 className="c_borderTitle n_mt c_borderTitle__dosha ">
            <span>ドーシャ</span>
          </h2>
          <CSliderMedia
            data={[
              {
                img: [
                  {
                    img: {
                      src: '/assets/images/aesthetic/menu/img_aesheticmenu08.png',
                      alt: 'ドーシャ',
                    },
                  },
                ],
                title: <></>,
                text: (
                  <>
                    <p className="text">
                      アーユルヴェーダのトリートメントを行うときは、一人ひとり異なる体質（ドーシャ）を知るために丁寧な事前カウンセリングを行います。
                      <br />
                      心身を司るエネルギーであるドーシャのバランスを整え、最適なトリートメントをご提案します。
                    </p>
                  </>
                ),
              },
            ]}
            exClass="dosha"
          />
          <div></div>

          <section className="u_bgGrad p_aestheticSpTabTable c_simpleAccordionList c_simpleAccordionList_nt">
            <p className="c_headingLv3 u_tac">ドーシャの特徴</p>
            <div className="u_pc">
              <CSpTabTable
                data={{
                  header: ['外見', '性格', '体質'],
                  key: [
                    <>
                      ヴァータ <br />
                      ＝風
                    </>,
                    <>
                      ピッタ <br />
                      ＝火
                    </>,
                    <>
                      カパ <br />
                      ＝水
                    </>,
                  ],
                  items: [
                    {
                      content: [
                        <>
                          <ul className="c_circleList">
                            <li>痩せ気味で小柄</li>
                            <li>乾燥肌</li>
                            <li>シミや白髪が多い</li>
                          </ul>
                        </>,
                        <>
                          <ul className="c_circleList">
                            <li>中肉中背で筋肉質</li>
                            <li>脂性肌</li>
                            <li>細く薄い髪質</li>
                          </ul>
                        </>,
                        <>
                          <ul className="c_circleList">
                            <li>体格がよく、持久力がある</li>
                            <li>きめ細かく白い肌</li>
                            <li>毛髪の量が多い</li>
                          </ul>
                        </>,
                      ],
                    },
                    {
                      content: [
                        <>
                          <ul className="c_circleList">
                            <li>せっかち</li>
                            <li>機敏</li>
                            <li>気分次第で変わる</li>
                          </ul>
                        </>,
                        <>
                          <ul className="c_circleList">
                            <li>頭の回転が早い</li>
                            <li>情熱的</li>
                            <li>怒りっぽい</li>
                          </ul>
                        </>,
                        <>
                          <ul className="c_circleList">
                            <li>落ち着いている</li>
                            <li>辛抱強い</li>
                            <li>ゆっくりしている</li>
                          </ul>
                        </>,
                      ],
                    },
                    {
                      content: [
                        <>
                          <ul className="c_circleList">
                            <li>冷え性</li>
                            <li>胃腸の調子が変わりやすい</li>
                            <li>便秘気味</li>
                          </ul>
                        </>,
                        <>
                          <ul className="c_circleList">
                            <li>体温が高い</li>
                            <li>汗っかき</li>
                            <li>便通は良いが下痢しやすい</li>
                          </ul>
                        </>,
                        <>
                          <ul className="c_circleList">
                            <li>体温が低め</li>
                            <li>頭痛が起きやすい</li>
                            <li>風邪をひきやすい</li>
                          </ul>
                        </>,
                      ],
                    },
                  ],
                }}
              />
            </div>
            <div className="u_sp tab">
              <CTab defaultKey={0}>
                <CTabItem title="外見" tabKey={0}>
                  <div className="tab_item">
                    <p className="title">ヴァータ＝風</p>
                    <p className="price">
                      ・痩せ気味で小柄
                      <br />
                      ・乾燥肌
                      <br />
                      ・シミや白髪が多い
                    </p>
                  </div>
                  <div className="tab_item">
                    <p className="title">ピッタ＝火</p>
                    <p className="price">
                      ・中肉中背で筋肉質
                      <br />
                      ・脂性肌
                      <br />
                      ・細く薄い髪質
                    </p>
                  </div>
                  <div className="tab_item">
                    <p className="title">カパ＝水</p>
                    <p className="price">
                      ・体格がよく、持久力がある
                      <br />
                      ・きめ細かく白い肌
                      <br />
                      ・毛髪の量が多い
                    </p>
                  </div>
                </CTabItem>
                <CTabItem title="性格" tabKey={1}>
                  <div className="tab_item">
                    <p className="title">ヴァータ＝風</p>
                    <p className="price">
                      ・せっかち
                      <br />
                      ・機敏
                      <br />
                      ・気分次第で変わる
                    </p>
                  </div>
                  <div className="tab_item">
                    <p className="title">ピッタ＝火</p>
                    <p className="price">
                      ・頭の回転が早い
                      <br />
                      ・情熱的
                      <br />
                      ・怒りっぽい
                    </p>
                  </div>
                  <div className="tab_item">
                    <p className="title">カパ＝水</p>
                    <p className="price">
                      ・落ち着いている
                      <br />
                      ・辛抱強い
                      <br />
                      ・ゆっくりしている
                    </p>
                  </div>
                </CTabItem>
                <CTabItem title="体質" tabKey={2}>
                  <div className="tab_item">
                    <p className="title">ヴァータ＝風</p>
                    <p className="price">
                      ・冷え性
                      <br />
                      ・胃腸の調子が変わりやすい
                      <br />
                      ・便秘気味
                    </p>
                  </div>
                  <div className="tab_item">
                    <p className="title">ピッタ＝火</p>
                    <p className="price">
                      ・体温が高い
                      <br />
                      ・汗っかき
                      <br />
                      ・便通は良いが下痢しやすい
                    </p>
                  </div>
                  <div className="tab_item">
                    <p className="title">カパ＝水</p>
                    <p className="price">
                      ・体温が低め
                      <br />
                      ・頭痛が起きやすい
                      <br />
                      ・風邪をひきやすい
                    </p>
                  </div>
                </CTabItem>
              </CTab>
            </div>
          </section>
          <CSectTitle
            title={{
              ja: 'スンダリ',
              en: <>SUNDÃRI</>,
            }}
            id="sundari"
          />
          <p className="c_sectLead u_mbMedium">
            アーユルヴェーダを現代人のニーズに合わせた
            <br />
            ニューヨーク生まれのスキンケアブランド「SUNDARI」を用いた様々なメニューをご提供します。
          </p>
          <CHeroImg
            exClass="u_mbMedium"
            img={{
              src: '/assets/images/aesthetic/menu/img_aesheticmenu05.png',
              alt: '',
            }}
          />
          <section className="p_aesthetic">
            <div className="p_colorCardUnit c_colorCardUnit__col1 ">
              <div className="p_colorCard">
                <CSectTitle02
                  title={{
                    ja: 'ボディ',
                    en: <>BODY</>,
                  }}
                />
                <div className="p_colorCard_text">
                  肌にリッチな潤いを与える「バーム」を使用した全身のトリートメント。
                  <br />
                  つま先からヘッドまで、独自のテクニックでエネルギーの流れを整えながら、深いリラックスへと導きます。
                </div>
                <div className="p_colorCard_footer">
                  <CInlineDefinition
                    color="white"
                    data={[
                      {
                        title: '施術時間：',
                        text: <>90分</>,
                      },
                      {
                        title: '価格：',
                        text: (
                          <>
                            ＜一般＞ 24,000円 <br />
                            ＜会員＞ 22,800円 + ポイント5%〜
                            <br className="u_sp" />
                            ＜プリペイド＞ 19,200円
                          </>
                        ),
                      },
                    ]}
                  />
                  <p className="p_colorCard_btn">
                    <CBtnList
                      data={[
                        {
                          label: '予約する',
                          link: {
                            href: 'https://booking.ebica.jp/webrsv/plan/e014024501/12254/71701',
                            blank: true,
                          },
                          icon: 'arrow',
                          color: 'bgGreen',
                        },
                      ]}
                    />
                  </p>
                </div>
              </div>
            </div>

            <div className="p_colorCardUnit">
              <div className="p_colorCard">
                <CSectTitle02
                  title={{
                    ja: 'フェイシャル&デコルテ',
                    en: <>STANDARD</>,
                  }}
                />
                <div className="p_colorCard_text">
                  肌のコンディションを整え、健やかな状態へと導きます。
                  <br />
                  SUNDARIスキンケアの基本トリートメントです。
                </div>
                <div className="p_colorCardUnit__col2">
                  <div className="p_colorCard_footer">
                    <CInlineDefinition
                      color="white"
                      data={[
                        {
                          title: '施術時間：',
                          text: <>60分</>,
                        },
                        {
                          title: '価格：',
                          text: (
                            <>
                              ＜一般＞ 15,000円 <br />
                              ＜会員＞ 14,250円 + ポイント5%〜
                              <br />
                              ＜プリペイド＞ 12,000円
                            </>
                          ),
                        },
                      ]}
                    />
                    <p className="p_colorCard_btn">
                      <CBtnList
                        data={[
                          {
                            label: '予約する',
                            link: {
                              href: 'https://booking.ebica.jp/webrsv/plan/e014024501/12254/71702',
                              blank: true,
                            },
                            icon: 'arrow',
                            color: 'bgGreen',
                          },
                        ]}
                      />
                    </p>
                  </div>
                  <div className="p_colorCard_footer">
                    <CInlineDefinition
                      color="white"
                      data={[
                        {
                          title: '施術時間：',
                          text: <>90分</>,
                        },
                        {
                          title: '価格：',
                          text: (
                            <>
                              ＜一般＞ 19,500円 <br />
                              ＜会員＞ 18,525円 + ポイント5%〜
                              <br />
                              ＜プリペイド＞ 15,600円
                            </>
                          ),
                        },
                      ]}
                    />
                    <p className="p_colorCard_btn">
                      <CBtnList
                        data={[
                          {
                            label: '予約する',
                            link: {
                              href: 'https://booking.ebica.jp/webrsv/plan/e014024501/12254/71703',
                              blank: true,
                            },
                            icon: 'arrow',
                            color: 'bgGreen',
                          },
                        ]}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="p_colorCardUnit">
              <div className="p_colorCard">
                <CSectTitle02
                  title={{
                    ja: '肌タイプ別',
                    en: <>BY SKIN TYPE</>,
                  }}
                />
                <div className="p_colorCard_text">
                  アーユルヴェーダに基づく体質（肌質）別の化粧品を使用し、
                  <br className="u_pc" />
                  一人ひとりの体質（ドーシャ）に合わせたパーソナルトリートメントです。
                </div>
                <div className="p_colorCardUnit__col2">
                  <div className="p_colorCard_footer">
                    <CInlineDefinition
                      color="white"
                      data={[
                        {
                          title: '施術時間：',
                          text: <>60分</>,
                        },
                        {
                          title: '価格：',
                          text: (
                            <>
                              ＜一般＞ 16,000円 <br />
                              ＜会員＞ 15,200円 + ポイント5%〜
                              <br />
                              ＜プリペイド＞ 12,800円
                            </>
                          ),
                        },
                      ]}
                    />
                    <p className="p_colorCard_btn">
                      <CBtnList
                        data={[
                          {
                            label: '予約する',
                            link: {
                              href: 'https://booking.ebica.jp/webrsv/plan/e014024501/12254/71704',
                              blank: true,
                            },
                            icon: 'arrow',
                            color: 'bgGreen',
                          },
                        ]}
                      />
                    </p>
                  </div>
                  <div className="p_colorCard_footer">
                    <CInlineDefinition
                      color="white"
                      data={[
                        {
                          title: '施術時間：',
                          text: <>90分</>,
                        },
                        {
                          title: '価格：',
                          text: (
                            <>
                              ＜一般＞ 20,500円 <br />
                              ＜会員＞ 19,475円 + ポイント5%〜
                              <br />
                              ＜プリペイド＞ 16,400円
                            </>
                          ),
                        },
                      ]}
                    />
                    <p className="p_colorCard_btn">
                      <CBtnList
                        data={[
                          {
                            label: '予約する',
                            link: {
                              href: 'https://booking.ebica.jp/webrsv/plan/e014024501/12254/71705',
                              blank: true,
                            },
                            icon: 'arrow',
                            color: 'bgGreen',
                          },
                        ]}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="p_colorCardUnit">
              <div className="p_colorCard">
                <CSectTitle02
                  title={{
                    ja: 'エイジングケア フェイシャル&デコルテ',
                    en: <>AGING CARE</>,
                  }}
                />
                <div className="p_colorCard_text">
                  エイジングケアラインのプロダクトを使用し、肌を引き締め、
                  <br className="u_pc" />
                  ハリ・弾力のあるエイジレスな素肌へ導くトリートメントです。
                </div>
                <div className="p_colorCardUnit__col2">
                  <div className="p_colorCard_footer">
                    <CInlineDefinition
                      color="white"
                      data={[
                        {
                          title: '施術時間：',
                          text: <>60分</>,
                        },
                        {
                          title: '価格：',
                          text: (
                            <>
                              ＜一般＞ 18,000円 <br />
                              ＜会員＞ 17,100円 + ポイント5%〜
                              <br />
                              ＜プリペイド＞ 14,400円
                            </>
                          ),
                        },
                      ]}
                    />
                    <p className="p_colorCard_btn">
                      <CBtnList
                        data={[
                          {
                            label: '予約する',
                            link: {
                              href: 'https://booking.ebica.jp/webrsv/plan/e014024501/12254/71706',
                              blank: true,
                            },
                            icon: 'arrow',
                            color: 'bgGreen',
                          },
                        ]}
                      />
                    </p>
                  </div>
                  <div className="p_colorCard_footer">
                    <CInlineDefinition
                      color="white"
                      data={[
                        {
                          title: '施術時間：',
                          text: <>90分</>,
                        },
                        {
                          title: '価格：',
                          text: (
                            <>
                              ＜一般＞ 22,500円 <br />
                              ＜会員＞ 21,375円 + ポイント5%〜
                              <br />
                              ＜プリペイド＞ 18,000円
                            </>
                          ),
                        },
                      ]}
                    />
                    <p className="p_colorCard_btn">
                      <CBtnList
                        data={[
                          {
                            label: '予約する',
                            link: {
                              href: 'https://booking.ebica.jp/webrsv/plan/e014024501/12254/71709',
                              blank: true,
                            },
                            icon: 'arrow',
                            color: 'bgGreen',
                          },
                        ]}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="p_colorCardUnit">
              <div className="p_colorCard">
                <CSectTitle02
                  title={{
                    ja: 'ブライトニング フェイシャル&デコルテ',
                    en: <>BRIGHTENING</>,
                  }}
                />
                <div className="p_colorCard_text">
                  肌の角質を除去し、ターメリックエキスが透明感のある艶めく肌へ導きます。
                </div>
                <div className="p_colorCardUnit__col2">
                  <div className="p_colorCard_footer">
                    <CInlineDefinition
                      color="white"
                      data={[
                        {
                          title: '施術時間：',
                          text: <>60分</>,
                        },
                        {
                          title: '価格：',
                          text: (
                            <>
                              ＜一般＞ 18,000円 <br />
                              ＜会員＞ 17,100円 + ポイント5%〜
                              <br />
                              ＜プリペイド＞ 14,400円
                            </>
                          ),
                        },
                      ]}
                    />
                    <p className="p_colorCard_btn">
                      <CBtnList
                        data={[
                          {
                            label: '予約する',
                            link: {
                              href: 'https://booking.ebica.jp/webrsv/plan/e014024501/12254/71698',
                              blank: true,
                            },
                            icon: 'arrow',
                            color: 'bgGreen',
                          },
                        ]}
                      />
                    </p>
                  </div>
                  <div className="p_colorCard_footer">
                    <CInlineDefinition
                      color="white"
                      data={[
                        {
                          title: '施術時間：',
                          text: <>90分</>,
                        },
                        {
                          title: '価格：',
                          text: (
                            <>
                              ＜一般＞ 22,500円 <br />
                              ＜会員＞ 21,375円 + ポイント5%〜
                              <br />
                              ＜プリペイド＞ 18,000円
                            </>
                          ),
                        },
                      ]}
                    />
                    <p className="p_colorCard_btn">
                      <CBtnList
                        data={[
                          {
                            label: '予約する',
                            link: {
                              href: 'https://booking.ebica.jp/webrsv/plan/e014024501/12254/71699',
                              blank: true,
                            },
                            icon: 'arrow',
                            color: 'bgGreen',
                          },
                        ]}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <CSectTitle
            title={{
              ja: 'その他',
              en: <>OTHER</>,
            }}
            id="others"
          />
          <p className="c_sectLead u_mbMedium">
            様々なトリートメントメニューを取り扱っております。
            <br />
            体の気になる部分を集中してトリートメントされたい方におすすめです。
          </p>
          <CHeroImg
            exClass="u_mbMedium"
            img={{
              src: '/assets/images/aesthetic/menu/img_aesheticmenu07.png',
              alt: '',
            }}
          />
          <CColorCard
            exClass="u_mbExLarge"
            data={[
              {
                title: {
                  ja: 'スリミングボディ',
                  en: 'SLIMMING BODY',
                },
                text: (
                  <>
                    蓄積された脂肪やセルライトに働きかけるトリートメントで、ツヤのある立体的なボディラインへ導きます。老廃物や水分の滞りによっておこるむくみのケアにも。
                  </>
                ),
                definition: [
                  {
                    title: '施術時間：',
                    text: <>60分</>,
                  },
                  {
                    title: '価格：',
                    text: (
                      <>
                        ＜一般＞ 18,000円 <br />
                        ＜会員＞ 17,100円 + ポイント5%〜
                        <br />
                        ＜プリペイド＞ 14,400円
                      </>
                    ),
                  },
                ],
                btn: {
                  label: '予約する',
                  link: {
                    href: 'https://booking.ebica.jp/webrsv/plan/e014024501/12254/71694',
                    blank: true,
                  },
                  color: 'bgGreen',
                },
              },
              {
                title: {
                  ja: 'ストーンセラピー',
                  en: 'STONE THERAPY',
                },
                text: (
                  <>
                    ホットストーン（玄武岩）を使用。遠赤外線が身体の深部までやさしく温めながら、冷えやストレスをケアします。
                  </>
                ),
                definition: [
                  {
                    title: '施術時間：',
                    text: <>60分</>,
                  },
                  {
                    title: '価格：',
                    text: (
                      <>
                        ＜一般＞ 18,000円 <br />
                        ＜会員＞ 17,100円 + ポイント5%〜
                        <br />
                        ＜プリペイド＞ 14,400円
                      </>
                    ),
                  },
                ],
                btn: {
                  label: '予約する',
                  link: {
                    href: 'https://booking.ebica.jp/webrsv/plan/e014024501/12254/71695',
                    blank: true,
                  },
                  color: 'bgGreen',
                },
              },
              {
                title: {
                  ja: 'シローヴィヤンガ',
                  en: 'SHIROBHYANGA',
                },
                text: (
                  <>
                    頭皮を中心とした首、肩、目元の集中トリートメント。眼精疲労やストレスをケアします。
                  </>
                ),
                definition: [
                  {
                    title: '施術時間：',
                    text: <>45分</>,
                  },
                  {
                    title: '価格：',
                    text: (
                      <>
                        ＜一般＞ 11,000円 <br />
                        ＜会員＞ 10,450円 + ポイント5%〜
                        <br />
                        ＜プリペイド＞ 8,800円
                      </>
                    ),
                  },
                ],
                btn: {
                  label: '予約する',
                  link: {
                    href: 'https://booking.ebica.jp/webrsv/plan/e014024501/12254/71696',
                    blank: true,
                  },
                  color: 'bgGreen',
                },
              },
              {
                title: {
                  ja: 'パーツトリートメント',
                  en: 'PARTS TREATMENT',
                },
                text: (
                  <>
                    お疲れの気になる部分のショートトリートメント。上半身、下半身のどちらかからお選びください。
                  </>
                ),
                definition: [
                  {
                    title: '施術時間：',
                    text: <>45分</>,
                  },
                  {
                    title: '価格：',
                    text: (
                      <>
                        ＜一般＞ 11,000円 <br />
                        ＜会員＞ 10,450円 + ポイント5%〜
                        <br />
                        ＜プリペイド＞ 8,800円
                      </>
                    ),
                  },
                ],
                btn: {
                  label: '予約する',
                  link: {
                    href: 'https://booking.ebica.jp/webrsv/plan/e014024501/12254/71697',
                    blank: true,
                  },
                  color: 'bgGreen',
                },
              },
            ]}
          />
          <CSectTitle
            title={{
              ja: 'プラン',
              en: <>PLAN</>,
            }}
          />
          <p className="c_sectLead u_mbMedium">
            リゾートホテルを満喫できる
            <br />
            女性にうれしいエステプラン
          </p>
          <CPostCard02 data={planChoice(planGet(), ['エステ'], 0)} />
          <ul className="c_noteList">
            <li>掲載の内容は予告なく変更する場合があります。</li>
            <li>画像はイメージです。</li>
          </ul>
        </LWrap>
      </section>
      <section id="guest-benefits" className="l_sect u_bgGray">
        <LWrap>
          <CSectTitle
            title={{
              ja: '宿泊者限定特典',
              en: (
                <>
                  GUEST
                  <span className="u_pc"> </span>
                  <br className="u_sp" />
                  BENEFITS
                </>
              ),
            }}
          />
          <p className="c_sectLead u_mbMedium">
            アーユソーマではお気軽にエステをご利用いただけるよう、
            <br />
            ご宿泊者限定の特典をご用意しております。
          </p>

          <div
            className="u_bgWhite c_simpleAccordionList aesthetic"
            style={{ padding: '56px', border: 'none' }}
          >
          <CBorderTitle
            size="small"
            title={<>宿泊者限定特典</>}
            exClass="n_mt"
          />
            <p className="title">全グランドメニュー20%OFF</p>
            <p className="text">
              ご宿泊のお客様（女性限定）は、アーユソーマの全グランドメニューを「20％OFF」でご利用いただけます。
            </p>
            <CBtnList
                data={[
                  {
                    label: 'グランドメニューはこちら',
                    link: {
                      href: '/assets/files/pdf/ayu_grandmenu.pdf',
                    },
                  },
                ]}
              />

           <p className="sp_title u_sp u_pt40">シーズナルエステ特別価格</p>
            <CSliderMedia
              reverse={true}
              data={[
                {
                  img: [
                    {
                      img: {
                        src: '/assets/images/aesthetic/img_aesthetic12.png',
                        alt: 'エステ',
                      },
                    },
                  ],
                  title: <></>,
                  text: (
                    <>
                      <p className="title">シーズナルエステ特別価格</p>
                      <p className="text">
                      ご宿泊のお客様（女性のお客様限定）はシーズナルエステを特別価格でご利用いただけます。詳しくはエステプラン「シーズナルエステ」をご覧ください。
                      <br />
                      ※こちらは2025年1月3日（金）～2025年2月28日（金）までの特典となります。
                      </p>
                    </>
                  ),
                  btn: {
                    link: {
                      href: '/aesthetic/pzd09bpe99uj/',
                    },
                    label: '詳しくはこちら',
                  },
                },
              ]}
            />

            <p className="sp_title u_sp u_pt40">宿泊者限定アニバーサリーエステプラン</p>
            <CSliderMedia
              reverse={true}
              data={[
                {
                  img: [
                    {
                      img: {
                        src: '/assets/images/aesthetic/img_aesthetic13.png',
                        alt: 'エステ',
                      },
                    },
                  ],
                  title: <></>,
                  text: (
                    <>
                      <p className="title">宿泊者限定アニバーサリーエステプラン</p>
                      <p className="text">
                      お誕生日や結婚記念日など、一年に一度のアニバーサリーを贅沢な気分でお過ごしいただけるようご宿泊者限定のアニバーサリーエステプランをご用意しております。
                      </p>
                    </>
                  ),
                  btn: {
                    link: {
                      href: '/aesthetic/lhqkeot5ya3p/',
                    },
                    label: '詳しくはこちら',
                  },
                },
              ]}
            />
          </div>
        </LWrap>
      </section>
      <section className="l_sect">
        <LWrap>
          <CSectTitle
            title={{
              ja: '営業時間｜ご利用案内',
              en: <>GUIDE</>,
            }}
          />
          <CDefinition
            data={[
              {
                title: <>場所</>,
                text: <>仙台ロイヤルパークホテル3F</>,
              },
              {
                title: <>営業時間</>,
                text: <>
                日曜日 ～ 木曜日 ／ 10:00～19:30
                <br />
                金曜日・土曜日・祝前日 ／ 10:00～21:00
                </>,
              },
              {
                title: <>ご予約について</>,
                text: (
                  <>
                    事前予約制
                  </>
                ),
              },
              {
                title: <>対象者</>,
                text: (
                  <>
                    18歳以上の女性の方
                    <ul className="c_noteList">
                      <li>
                        ペアトリートメントプランのみ、男女でのご利用が可能です。
                      </li>
                    </ul>
                  </>
                ),
              },
              {
                title: <>会員特典</>,
                text: (
                  <>
                    「ザ
                    クラブ・ロイヤルパークホテルズ」会員の方は、一般価格から5%OFFの会員優待価格にてご利用いただけます。会員ステージに応じたポイントも付与されます。
                  </>
                ),
              },
              {
                title: <>禁忌事項</>,
                text: (
                  <>
                    妊娠・心臓疾患・循環器系障害・静脈瘤・皮膚疾患・体調不良、その他持病のある方や医師による治療を受けている方は、トリートメントをお受けいただけませんのでご了承ください。
                    <br />
                    また、アルコール摂取後のご利用もお控えいただきますようお願いいたします。詳しくはお問い合わせください。
                  </>
                ),
              },
              {
                title: <>キャンセル料</>,
                text: (
                  <>
                    ご予約の変更、キャンセルにつきましては前日18時までにご連絡ください。
                    <ul className="c_noteList">
                      <li>
                        当日キャンセルの場合、キャンセル料として50%を頂戴します。
                      </li>
                    </ul>
                  </>
                ),
              },
              {
                title: <>お問合せ</>,
                text: (
                  <>
                    <a href="tel:0223773896">022-377-3896</a>
                    （受付時間10:00～18:00）
                    <br />
                    <ul className="c_noteList">
                      <li>
                        18:00～翌10:00は、
                        <a href="tel:0223771111">022-377-1111</a>
                        までご連絡ください。
                      </li>
                    </ul>
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <section
        id="pre-paid-ticket"
        className="l_sect c_simpleAccordionList aesthetic"
      >
        <LWrap>
          <CSectTitle
            title={{
              ja: 'プリペイドチケット',
              en: (
                <>
                  PRE-PAID
                  <span className="u_pc"> </span>
                  <br className="u_sp" />
                  TICKET
                </>
              ),
            }}
          />
          <p className="c_sectLead u_mbMedium">
            アーユソーマでは、お得なプリペイドチケットをご用意しております。
            <br />
            継続してご利用いただきやすいプリペイドチケットをご活用ください。
          </p>
          <div className="u_pc">
            <CSpTabTable
              data={{
                header: ['Aプラン', 'Bプラン', 'Cプラン'],
                key: ['料金', '有効期間', '優待①', '優待②'],
                items: [
                  {
                    content: [
                      '150,000円',
                      '2年間',
                      '全グランドメニューをプリペイドチケット価格で利用可能',
                      'グランドメニュー60分（18,000円コース相当）の1回分無料チケットプレゼント（ご本人様もしくはご家族・ご友人の方へ譲渡可能）',
                    ],
                  },
                  {
                    content: [
                      '75,000円',
                      '1年間',
                      '全グランドメニューをプリペイドチケット価格で利用可能',
                      <>
                        エステオプション「パーツトリートメント」プレゼント
                        <div className="note">30分：5,000円相当</div>
                      </>,
                    ],
                  },
                  {
                    content: [
                      '45,000円',
                      '半年間',
                      '全グランドメニューをプリペイドチケット価格で利用可能',
                      '-',
                    ],
                  },
                ],
              }}
            />
          </div>
          <div className="u_sp tab">
            <CTab defaultKey={0}>
              <CTabItem title="Aプラン" tabKey={0}>
                <div className="tab_item">
                  <p className="title">料金</p>
                  <p className="price">150,000円</p>
                </div>
                <div className="tab_item">
                  <p className="title">有効期間</p>
                  <p className="price">2年間</p>
                </div>
                <div className="tab_item">
                  <p className="title">優待①</p>
                  <p className="price">
                    全グランドメニューをプリペイドチケット価格で利用可能
                  </p>
                </div>
                <div className="tab_item">
                  <p className="title">優待②</p>
                  <p className="price">
                    グランドメニュー60分（18,000円コース相当）の1回分無料チケットプレゼント（ご本人様もしくはご家族・ご友人の方へ譲渡可能）
                  </p>
                </div>
              </CTabItem>
              <CTabItem title="Bプラン" tabKey={1}>
                <div className="tab_item">
                  <p className="title">料金</p>
                  <p className="price">75,000円</p>
                </div>
                <div className="tab_item">
                  <p className="title">有効期間</p>
                  <p className="price">1年間</p>
                </div>
                <div className="tab_item">
                  <p className="title">優待①</p>
                  <p className="price">
                    全グランドメニューをプリペイドチケット価格で利用可能
                  </p>
                </div>
                <div className="tab_item">
                  <p className="title">優待②</p>
                  <p className="price">
                    エステオプション「パーツトリートメント」プレゼント
                    <div className="note">30分：5,000円相当</div>
                  </p>
                </div>
              </CTabItem>
              <CTabItem title="Cプラン" tabKey={2}>
                <div className="tab_item">
                  <p className="title">料金</p>
                  <p className="price">45,000円</p>
                </div>
                <div className="tab_item">
                  <p className="title">有効期間</p>
                  <p className="price">半年間</p>
                </div>
                <div className="tab_item">
                  <p className="title">優待①</p>
                  <p className="price">
                    全グランドメニューをプリペイドチケット価格で利用可能
                  </p>
                </div>
                <div className="tab_item">
                  <p className="title">優待②</p>
                  <p className="price">-</p>
                </div>
              </CTabItem>
            </CTab>
          </div>
        </LWrap>
      </section>
      <LPlan />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
